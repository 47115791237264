import React, { lazy, Suspense } from 'react';
import Loading from '../loading';
import FormBuilder from '../base/form_builder';
/*
import { withFormik } from 'formik';
const Yup = require('yup');
*/
const withFormik = window.withFormik;
const Yup = window.Yup;

const FormEdit = lazy(() => import('./edit/form_edit'));

const AjaxAPI = window.AjaxAPI;

const BlockForm = withFormik({
  mapPropsToValues(props) {
    const values = {};
    if (props.block.instance.data) {
      props.block.instance.data.forEach(function(o) { values[o.id] = '' });
    }

    return values;
  },
  validationSchema: Yup.object().shape({
  }),
  handleSubmit(values, { props, resetForm, setErrors, setSubmitting }) {
    const _props = props;
    var post_data = {
      'block_instance_id': props.block.instance.id,
      'block_type': 'form',
      'data': values
    };
    (new AjaxAPI).post(post_data, '/forms/submit', function(data){
      resetForm();
      _props.setMessageHidden(false);
      setTimeout(function () { _props.setMessageHidden(true); }, 3000);
    });
    setSubmitting(false)
  }
})(FormBuilder)

class FormBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      messageHidden: true
    }

    this.setMessageHidden = this.setMessageHidden.bind(this);
  }

  setMessageHidden(state) {
    this.setState({ messageHidden: state });
  }

  render() {
    if (this.props.edit) {
      return (
        <Suspense fallback={<Loading/>}>
          <FormEdit {...this.props} />
        </Suspense>
      )
    } else {
      const block = this.props.block;
      const instance = block.instance
      const submitLabel = instance.attributes_hash.submit_label || 'Submit';
      const completeMessage = instance.attributes_hash.complete_message || "Thank you!";
      const cssClass = instance.attributes_hash.css_class;
      const formData = block.instance.data || [];

      return (
        <div className='form'>
          <BlockForm {...this.props}
            formData={formData}
            submitLabel={submitLabel}
            cssClass={cssClass}
            completeMessage={completeMessage}
            messageHidden={this.state.messageHidden}
            setMessageHidden={this.setMessageHidden}
          />
        </div>
      )
    }
  }
}

export default FormBlock;
