import React from 'react';
import Button from '../button';

const InputWrapper = window.InputWrapper
const withFormik= window.withFormik;
const Form = window.Form;

class FormFields extends React.Component {
  constructor(props) {
    super(props);

    this.fieldTypeToComponent = this.fieldTypeToComponent.bind(this);
    this.fieldTypeToType = this.fieldTypeToType.bind(this);
    this.optionsToArray = this.optionsToArray.bind(this);
  }

  fieldComponentMap = {
    'text': 'input',
    'text_area': 'textarea',
    'select': 'select',
    'radio': 'input',
    'checkbox': 'input',
    'image': 'input'

  }

  fieldTypeMap = {
    'text': 'text',
    'text_area': null,
    'select': null,
    'radio': 'radio',
    'checkbox': 'checkbox',
    'image': 'image',
    'audio': 'audio'

  }

  fieldTypeToComponent(fieldType) {
    return this.fieldComponentMap[fieldType];
  }

  fieldTypeToType(fieldType) {
    return this.fieldTypeMap[fieldType];
  }

  optionsToArray(options) {
    if (!options) { return null }

    var arr = options.split('\n');
    return arr.map(o => ({label: o, value: o}));
  }


  render() {
    const data = this.props.formData || [];

    return (
      <React.Fragment>
      {data.map((o, i) => <InputWrapper
                            {...this.props}
                            key={o.id}
                            id={o.id}
                            item={o}
                            type={this.fieldTypeToType(o.field_type)}
                            component={this.fieldTypeToComponent(o.field_type)}
                            name={o.id}
                            options={this.optionsToArray(o.options)}
                            label={o.label}
                            labelClass={this.props.labelClass}
                            placeholder={o.placeholder}
                            touched={this.props.touched}
                            errors={this.props.errors}
                          />)}
      </React.Fragment>
    );
  }
}

class FormBuilder extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const submitLabel = this.props.submitLabel || 'Submit';
    var cssClass = this.props.cssClass;
    var labelClass = '';
    var inline = false;
    if (cssClass && cssClass.includes('inline')) {
      cssClass = 'form-inline';
      labelClass = 'sr-only';
      inline = true;
    }
    const messageClass = 'complete-message btn ' +
      (this.props.messageHidden ? 'hidden' : '');
    const message = this.props.completeMessage || "Thank you!";

    return (
      <Form className={cssClass}>
        <FormFields {...this.props} labelClass={labelClass} />
        <Button
          label={submitLabel}
          type='submit'
          disabled={this.props.isSubmitting}
          buttonClass='btn'
        />
        {this.props.cancelHandler &&
          <Button
            label='Cancel'
            handleClick={this.props.cancelHandler}
            buttonClass='btn'
          />
        }
      </Form>
    );
  }
}

export default FormBuilder
